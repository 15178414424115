<template>
  <div class="patient-profile">
    <div class="patient-profile__header">
      <div class="breadcrumbs">
        
        <router-link 
          v-if="userHasPermission('FpPac1')"
          to="/pacientes" 
          tag="span" 
          class="link"
        >
          Meus pacientes
        </router-link>
        <span v-else> Meus pacientes </span>
        <ChevronRight class="chevron" />
        <span v-if="!patient?.name"> Perfil do paciente </span>
        <span v-else> {{ patient?.name }} </span>
      </div>
      <b-button
        v-can="'FiConCred2'"
        v-if="hasCreditPatientFeature"
        class="wh-button"
        variant="primary"
        @click="openNewBillToReceiveModal"
      >
        <PlusWhite class="" />
        <span> Novo depósito </span>
      </b-button>
    </div>
    <Tab
      ref="patient_profile_tabs"
      :index="currentTabIndex"
      v-bind:keys="tabs"
      v-on:onChange="onChangeTab"
      id="patient-profile-tabs"
      class="patient-profile__tabs"
    >
      <PatientDashboard
        v-if="tabs[currentTabIndex] === 'Prontuário'"
        class="tab"
        @goPatientForm="onChangeTab"
      />

      <PatientForm v-if="tabs[currentTabIndex] === 'Cadastro'" class="tab" />

      <AccountContent
        :patient="patient"
        :showAccountPatientData="false"
        v-if="tabs[currentTabIndex] === 'Conta'"
        class="tab"
        @open-bill="openBill"
      />

      <AppointmentHistory
        :patient="patient"
        v-if="tabs[currentTabIndex] === 'Histórico de agendamentos'"
        @openAppointment="openAppointment"
        class="tab"
      />

      <PatientDocs 
        v-if="tabs[currentTabIndex] === 'Documentos e exames'"
        :patient="patient"
        class="tab"
      >
        Documentos e exames
      </PatientDocs>
    </Tab>

    <BillsToReceiveModal
      :key="reloadModal"
      :billToReceiveId="billToReceiveId"
      :categorySelected="categorySelected"
      :patientSelected="patient"
      :showFields="showFields"
      @onHide="getPatient"
    />

    <AppointmentModal :appointmentId="appointmentId" />
  </div>
</template>
<script>
import api from '@/modules/financial/api'
import { getCurrentClinic, userHasPermission } from '@/utils/localStorageManager'
import { showFeature, hasPermissionPatientCreditCategory } from '@/utils/permissionsHelp'
export default {
  name: 'PatientProfile',
  components: {
    Tab: () => import('@/components/TabPills'),
    PatientDashboard: () => import('@/views/Patients/PatientDashboard.vue'),
    PatientForm: () => import('@/views/Patients/PatientForm.vue'),
    ChevronRight: () => import('@/assets/icons/chevron-right.svg'),
    AccountContent: () =>
      import('@/components/AppointmentModal/AccountContent.vue'),
    AppointmentHistory: () =>
      import('@/components/AppointmentModal/AppointmentHistory.vue'),
    BillsToReceiveModal: () =>
      import('@/modules/financial/modals/BillsToReceiveModal'),
    PlusWhite: () => import('@/assets/icons/plus-white.svg'),
    AppointmentModal: () => import('@/components/AppointmentModal/index.vue'),
    PatientDocs: () => import('@/layouts/Patient/PatientDocs.vue')
  },

  async mounted() {
    const isLoading = this.$loading.show()
    await this.getPatient()

    if (this.hasPatientCreditPermissions) {
      this.getPatientDepositCategory()
    }
    this.redirectTab()
    isLoading.hide()
  },

  data() {
    return {
      currentTabIndex: null,
      tabs: ['Prontuário', 'Cadastro', 'Conta', 'Histórico de agendamentos', 'Documentos e exames'],
      patient: null,
      billToReceiveId: null,
      reloadModal: 1,
      categorySelected: null,
      clinic: getCurrentClinic(),
      showFields: {
        showBeneficiaryField: false,
        showFieldHealthPlan: false,
        showFieldAmount: false,
        showFieldDiscount: false,
        showFieldCurrency: false,
        showFieldFinalAmount: false,
        showFieldPaymentVendorId: true
      },
      appointmentId: null
    }
  },

  computed: {
    hasPatientCreditPermissions() {
      return this.hasPermissionPatientCreditCategory() && this.hasCreditPatientFeature
    },
    hasCreditPatientFeature() {
      return this.showFeature('Caução do paciente')
    }
  },


  methods: {
    userHasPermission,
    showFeature,
    hasPermissionPatientCreditCategory,
    onChangeTab(index) {
      this.currentTabIndex = index
    },

    async getPatient() {
      try {
        const response = await this.api.getPerson(this.$route.params.id)

        if (response) {
          this.patient = response.data
        }
      } catch (error) {
        this.$toast.error(
          'Não foi possível carregar os dados do paciente, tente novamente mais tarde ou contate o suporte.'
        )
        console.error(error)
      }
    },
    async getPatientDepositCategory() {
      if (this.clinic?.id) {
        const response = await api.getCreditPatientCategory(this.clinic.id)

        if (response) {
          this.categorySelected = response.data
        }
      }
    },
    async redirectTab() {
      const route = this.$route.path.split('/').pop()

      switch (route) {
        case 'edit':
          this.currentTabIndex = 1
          break
        case 'account':
          this.currentTabIndex = 2
          break
        default:
          this.currentTabIndex = 0
          break
      }
    },
    openNewBillToReceiveModal() {
      this.$bvModal.show('bills-to-receive-modal')
    },
    openAppointment(appointmentId) {
      this.appointmentId = appointmentId
      this.$bvModal.show('appointment-modal');
    },
    openBill(billId) {
      this.reloadModal++
      setTimeout(() => {
        this.billToReceiveId = billId
        this.$bvModal.show('bills-to-receive-modal')
      }, 300);
    }
  }
}
</script>
<style lang="scss" scoped>
.patient-profile {
  padding: 0 24px 10px;
  text-align: left;

  .patient-profile__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    
    margin: 15px 0;

    .wh-button {
      display: flex;
      flex-direction: row;
      align-items: center;

      span {
        margin: 0 0 0 3px;
        white-space: nowrap;
      }
    }

    .breadcrumbs {
      width: 100%;
      font-weight: 600;
      font-size: 16px;

      .chevron {
        width: 24px;
        stroke: var(--neutral-600);
        margin: 0 8px;
      }

      .link {
        color: var(--blue-500);
        cursor: pointer;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
</style>